import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useBlogPostService from '../services/blogPostService';
import MDEditor from '@uiw/react-md-editor';
import Switch from 'react-bootstrap/Switch';

const BlogPostCreate = () => {
    const navigate = useNavigate();
    const { createBlogPost } = useBlogPostService();
    const [isHome, setIsHome] = useState(true);
    const [showPreview, setShowPreview] = useState(false);

    const { user } = useAuth0();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [blogPost, setBlogPost] = useState({
        header: "",
        imageUrl: "",
        body: ""
    });

    const userHasAdminRole = () => {
        return user?.['https://gluck.blog/roles']?.includes('BlogAdmin');
    };

    const updateMd = (text) => {
        setBlogPost({ ...blogPost, body: text });
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBlogPost({ ...blogPost, [name]: value });
    };

    const updateCategoryNames = (isHome) => {
        const newCategoryName = isHome
            ? 'home'
            : 'road';
        console.log(newCategoryName)
        setBlogPost({ ...blogPost, categoryNames: [newCategoryName] });
    };
    const handleSetIsHome = (isHome) => {
        setIsHome(isHome);
        updateCategoryNames(isHome);
    }

    const initIsHome = (categories) => {
        var isHomeValue = categories.includes("home");
        setIsHome(isHomeValue);
    }
    const handleSetShowPreview = (showPrev) => {
        setShowPreview(showPrev);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            console.log(blogPost);
            var response = await createBlogPost(blogPost);
            if (response.ok) {
                console.log('Blog post created successfully!');
                setBlogPost({ Header: "", ImageUrl: "", Body: "" });
                navigate('/posts');
            } else {
                console.error('Blog post creation failed!');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='mainContainer'>

            <Row>
                <Col xs={9} xl={9} className='postContainer'>
                    <div className='user-form'>
                        <div className='heading'>
                            {isLoading && <p>Loading...</p>}
                            {error && <p>Error: {error}</p>}
                            <p>Create Blog Post</p>
                            <div style={{ margin: '5px' }}>
                                <Switch
                                    checked={showPreview}
                                    onChange={() => handleSetShowPreview(!showPreview)}
                                    label={'Show preview'}
                                />
                            </div>
                            <label style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
                                <span style={{ marginRight: '8px' }}>Road</span>
                                <Switch
                                    checked={isHome}
                                    onChange={() => handleSetIsHome(!isHome)}
                                />
                                <span>Home</span>
                            </label>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="header" className="form-label">Header</label>
                                <input type="text" className="form-control" id="header" name="header" value={blogPost.header} onChange={handleInputChange} />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="imageUrl" className="form-label">Image URL</label>
                                <input type="text" className="form-control" id="imageUrl" name="imageUrl" value={blogPost.imageUrl} onChange={handleInputChange} />
                            </div>
                            <div className="mb-12" data-color-mode="light">
                                <label htmlFor="body" className="form-label">Body</label>
                                <MDEditor
                                    value={blogPost.body}
                                    onChange={updateMd}
                                />
                            </div>
                            <br />
                            {
                                showPreview &&
                                <div className="mb-12 container" data-color-mode="light">
                                    <MDEditor.Markdown source={blogPost.body} style={{ whiteSpace: 'pre-wrap', display: 'block' }} />
                                </div>
                            }
                            <div>
                                <label htmlFor="jscode">JavaScript Code</label>
                                <textarea
                                    className="form-control"
                                    id="jsCode"
                                    name="jsCode"
                                    value={blogPost.jsCode}
                                    onChange={handleInputChange}
                                    rows="6"
                                />
                            </div>
                            {userHasAdminRole() &&
                                <button type="submit" className="btn btn-primary submit-btn">Submit</button>
                            }
                        </form>
                    </div>
                </Col>

                <Col xs={3} xl={3}>
                    <div className="vertical-line"></div>
                </Col>
            </Row>
        </div>
    );
};

export default withAuthenticationRequired(BlogPostCreate, {
    onRedirecting: () => (
        <div>Redirecting you to the login page...</div>
    )
});